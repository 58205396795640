<template>
  <div class="content1">
    <div v-if="$parent.activeChildClass == 1" class="content-child1">
      <div class="info1">
        <div class="the-input">
          <div class="flex justify-between">
            <label class="font-bold"> {{ $t("tradeName") }} </label>
          </div>
          <input
            ref="tradeInput"
            disabled
            type="text"
            v-if="hasAccess"
            class="input"
            :placeholder="client.name"
          />
          <input
            ref="tradeInput"
            disabled
            type="text"
            v-else
            class="input"
            :placeholder="user.first_name"
          />
        </div>
        <div class="the-input">
          <label class="font-bold"> {{ $t("theNationalNumber") }} </label>
          <input
            disabled
            type="text"
            class="input"
            :placeholder="client.national_id"
          />
        </div>
        <div class="the-input" style="position: relative">
          <label class="font-bold"> {{ $t("customerCode") }} </label>
          <p class="input flex items-center">{{ client.code }}</p>
          <input
            ref="copyCodeInput"
            type="hidden"
            :value="client.code"
            :placeholder="client.code"
          />
          <i @click="copyText('copyCodeInput')">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14.8"
              height="18.5"
              viewBox="0 0 14.8 18.5"
            >
              <g
                id="Icon_ionic-ios-copy"
                data-name="Icon ionic-ios-copy"
                transform="translate(-4.5 -1.125)"
              >
                <path
                  id="Path_1276"
                  data-name="Path 1276"
                  d="M24.143,4.944h3.469a.232.232,0,0,0,.231-.231h0a1.076,1.076,0,0,0-.385-.821L24.486,1.417a1.442,1.442,0,0,0-.794-.285h0a.285.285,0,0,0-.285.285V4.212A.738.738,0,0,0,24.143,4.944Z"
                  transform="translate(-8.543 -0.003)"
                  fill="#6e6b7b"
                />
                <path
                  id="Path_1277"
                  data-name="Path 1277"
                  d="M16.407,4.208V1.125H11.358a1.237,1.237,0,0,0-1.233,1.233v12.95a1.237,1.237,0,0,0,1.233,1.233h9.25a1.237,1.237,0,0,0,1.233-1.233V5.943h-3.7A1.737,1.737,0,0,1,16.407,4.208Z"
                  transform="translate(-2.542)"
                  fill="#6e6b7b"
                />
                <path
                  id="Path_1278"
                  data-name="Path 1278"
                  d="M6.5,18.421V5.625H5.733A1.237,1.237,0,0,0,4.5,6.858V20.425a1.237,1.237,0,0,0,1.233,1.233H15.6a1.237,1.237,0,0,0,1.233-1.233v-.771h-9.1A1.237,1.237,0,0,1,6.5,18.421Z"
                  transform="translate(0 -2.033)"
                  fill="#6e6b7b"
                />
              </g>
            </svg>
          </i>
        </div>
        <div class="the-input">
          <div class="flex justify-between">
            <label class="font-bold"> {{ $t("mail") }} </label>
          </div>
          <input
            ref="mailInput"
            disabled
            type="text"
            v-if="hasAccess"
            class="input"
            :placeholder="client.email"
          />
          <input
            ref="mailInput"
            disabled
            type="text"
            v-else
            class="input"
            :placeholder="user.email"
          />
        </div>
        <div class="the-input">
          <label class="font-bold"> {{ $t("facebookLink") }} </label>
          <input
            disabled
            type="text"
            class="input"
            :placeholder="client.facebook_url"
          />
        </div>
      </div>
      <div class="info2">
        <div class="title flex items-center">
          <h3>{{ $t("administratorInfo") }}</h3>
          <div class="line"></div>
        </div>
        <div class="the-input">
          <label class="font-bold"> {{ $t("responsibleBranch") }} </label>
          <input
            disabled
            type="text"
            class="input"
            :placeholder="responsible_branch"
          />
        </div>
        <div class="the-input">
          <label class="font-bold"> {{ $t("nameOfOfficial") }} </label>
          <input
            disabled
            type="text"
            class="input"
            :placeholder="client.responsible_name"
          />
        </div>
        <div class="the-input" style="position: relative">
          <label class="font-bold"> {{ $t("officialNumber") }} </label>
          <input
            disabled
            type="text"
            class="input"
            :placeholder="client.responsible_mobile"
          />
        </div>
        <div class="the-input">
          <label class="font-bold"> {{ $t("theFollowUpName") }} </label>
          <input
            disabled
            type="text"
            class="input m-0"
            :placeholder="client.follow_up_name"
          />
        </div>
        <div class="the-input">
          <label class="font-bold"> {{ $t("numberOfFollowUp") }} </label>
          <input
            disabled
            type="text"
            class="input m-0"
            :placeholder="client.follow_up_mobile"
          />
        </div>
      </div>
    </div>
    <div v-else-if="$parent.activeChildClass == 2" class="content-child2">
      <div class="info1">
        <div class="the-input">
          <label class="font-bold"> {{ $t("extraKilogram") }} </label>
          <input
            disabled
            type="text"
            class="input"
            :placeholder="client.kilo_price"
          />
        </div>
        <div class="the-input">
          <label class="font-bold"> {{ $t("sellerCode") }} </label>
          <input
            disabled
            type="text"
            class="input"
            :placeholder="client.merchant_code"
          />
        </div>
        <div class="the-input" style="position: relative">
          <label class="font-bold"> {{ $t("sellerNotes") }} </label>
          <input
            disabled
            type="text"
            class="input"
            :placeholder="client.merchant_note"
          />
        </div>
        <div class="the-input">
          <label class="font-bold"> {{ $t("numberDeliveryDay") }} </label>
          <input
            disabled
            type="text"
            class="input m-0"
            :placeholder="client.max_delivery_days"
          />
        </div>
        <div class="the-input">
          <label class="font-bold"> {{ $t("typeProducts") }} </label>
          <input
            disabled
            type="text"
            class="input m-0"
            :placeholder="client.product_type"
          />
        </div>
      </div>
    </div>
    <div v-else-if="$parent.activeChildClass == 3" class="content-child3">
      <LocationInfo />
    </div>
    <div v-else-if="$parent.activeChildClass == 4" class="content-child4">
      <div class="flex flex-wrap">
        <div class="out-box">
          <div class="box">
            <i>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="45.047"
                height="40.041"
                viewBox="0 0 45.047 40.041"
              >
                <path
                  id="Icon_awesome-boxes"
                  data-name="Icon awesome-boxes"
                  d="M43.8,22.523H37.539v7.508l-2.5-1.666-2.5,1.666V22.523H26.277a1.255,1.255,0,0,0-1.251,1.251V38.79a1.255,1.255,0,0,0,1.251,1.251H43.8a1.255,1.255,0,0,0,1.251-1.251V23.775A1.255,1.255,0,0,0,43.8,22.523ZM13.764,17.518H31.282a1.255,1.255,0,0,0,1.251-1.251V1.251A1.255,1.255,0,0,0,31.282,0H25.026V7.508l-2.5-1.666-2.5,1.666V0H13.764a1.255,1.255,0,0,0-1.251,1.251V16.267A1.255,1.255,0,0,0,13.764,17.518Zm5.005,5.005H12.513v7.508l-2.5-1.666-2.5,1.666V22.523H1.251A1.255,1.255,0,0,0,0,23.775V38.79a1.255,1.255,0,0,0,1.251,1.251H18.769a1.255,1.255,0,0,0,1.251-1.251V23.775A1.255,1.255,0,0,0,18.769,22.523Z"
                  fill="#6e6b7b"
                />
              </svg>
            </i>
            <span class="title font-bold">{{ $t("stockService") }}</span>
            <span class="text">{{ $t("notSubscribed") }}</span>
          </div>
          <button class="btn-box">قريباً</button>
        </div>
        <div class="out-box">
          <div class="box">
            <i>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="45.151"
                height="39.507"
                viewBox="0 0 45.151 39.507"
              >
                <path
                  id="Icon_awesome-toolbox"
                  data-name="Icon awesome-toolbox"
                  d="M44.324,18.355l-3.99-3.99a2.822,2.822,0,0,0-2-.826H33.863V6.483A4.233,4.233,0,0,0,29.63,2.25H15.521a4.233,4.233,0,0,0-4.233,4.233v7.055H6.812a2.824,2.824,0,0,0-2,.826L.826,18.355a2.822,2.822,0,0,0-.826,2v7.3H11.288V26.236A1.411,1.411,0,0,1,12.7,24.825h2.822a1.411,1.411,0,0,1,1.411,1.411v1.411H28.219V26.236a1.411,1.411,0,0,1,1.411-1.411h2.822a1.411,1.411,0,0,1,1.411,1.411v1.411H45.151v-7.3A2.821,2.821,0,0,0,44.324,18.355ZM28.219,13.538H16.931V7.894H28.219ZM33.863,31.88a1.411,1.411,0,0,1-1.411,1.411H29.63a1.411,1.411,0,0,1-1.411-1.411V30.469H16.931V31.88a1.411,1.411,0,0,1-1.411,1.411H12.7a1.411,1.411,0,0,1-1.411-1.411V30.469H0v8.466a2.822,2.822,0,0,0,2.822,2.822H42.329a2.822,2.822,0,0,0,2.822-2.822V30.469H33.863Z"
                  transform="translate(0 -2.25)"
                  fill="#6e6b7b"
                />
              </svg>
            </i>
            <span class="title font-bold">{{ $t("packaging") }}</span>
            <span class="text">{{ $t("notSubscribed") }}</span>
          </div>
          <button class="btn-box">قريباً</button>
        </div>
        <div class="out-box">
          <div class="box">
            <i>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="49.883"
                height="39.903"
                viewBox="0 0 49.883 39.903"
              >
                <path
                  id="Icon_awesome-hands-helping"
                  data-name="Icon awesome-hands-helping"
                  d="M38.036,14.965H26.189V19.33a5.612,5.612,0,1,1-11.224,0V9.852l-5.058,3.04a4.978,4.978,0,0,0-2.424,4.271v3.687l-6.235,3.6a2.488,2.488,0,0,0-.912,3.406l6.235,10.8a2.5,2.5,0,0,0,3.406.912l8.059-4.653H28.683a4.993,4.993,0,0,0,4.988-4.988h1.247a2.491,2.491,0,0,0,2.494-2.494V22.447h.624a1.866,1.866,0,0,0,1.871-1.871V16.835A1.866,1.866,0,0,0,38.036,14.965ZM49.548,12.05l-6.235-10.8A2.5,2.5,0,0,0,39.907.335L31.847,4.988H23.882a5.026,5.026,0,0,0-2.642.756L18.628,7.373a2.477,2.477,0,0,0-1.169,2.112V19.33a3.118,3.118,0,1,0,6.235,0V12.471H38.036A4.368,4.368,0,0,1,42.4,16.835v2.221l6.235-3.6a2.5,2.5,0,0,0,.912-3.406Z"
                  transform="translate(0.002 -0.002)"
                  fill="#6e6b7b"
                />
              </svg>
            </i>
            <span class="title font-bold">{{ $t("insuranceService") }}</span>
            <span class="text">{{ $t("notSubscribed") }}</span>
          </div>
          <button class="btn-box">قريباً</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import aclAccess from "@/mixins/hasAccess";
import helper from "@/mixins/helper";
import notifyState from "@/mixins/notifyState";
import LocationInfo from "./LocationInfo.vue";
export default {
  name: "mainInfo",
  mixins: [aclAccess, helper, notifyState],
  data() {
    return {
      location: {
        longitude: "",
        latitude: "",
      },
      loading: false,
    };
  },
  components: {
    LocationInfo,
  },
  props: {},
  computed: {
    ...mapGetters("userData", [
      "client",
      "sub_client",
      "responsible_branch",
      "government",
      "error_msg",
      "user",
      "success_api",
    ]),
    isFormValid() {
      if (this.longitude != "" && this.latitude != "") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions("userData", ["updateLocation"]),
    async submit() {
      this.loading = true;
      if (this.isFormValid) {
        await this.updateLocation(this.location);
        if (this.success_api) {
          this.notifySuccess("editToast");
        } else {
          this.notifyFaild("faildAdd");
        }
      }
      this.loading = false;
    },
  },
  mounted() {
    this.$parent.active = true;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_setting.scss";
</style>
