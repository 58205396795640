<template>
  <SpinnerColor2 v-if="loading" />
  <div v-else class="addresss">
    <div
      v-for="(location, index) in locations"
      :key="index"
      :id="'location' + location.id"
      class="address"
      :class="location.is_fixed === 1 ? 'fixed-address' : ''"
    >
      <div
        class="title-address"
        :class="{ 'title-address-active': idAddress == location.id }"
        @click="check(location.id)"
      >
        <p>
          {{ location.is_fixed === 1 ? "الموقع الرئيسي" : "موقع فرعي" }}
          <span class="subtitle-address"> - {{ location.region }} </span>
        </p>
        <i
          class="icon-address"
          :class="idAddress == location.id ? 'active-icon-address' : ''"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="8.574"
            viewBox="0 0 8 4.574"
          >
            <path
              id="Icon_ionic-ios-arrow-forward"
              data-name="Icon ionic-ios-arrow-forward"
              d="M14.442,10.195,11.414,7.17a.569.569,0,0,1,0-.807.577.577,0,0,1,.81,0l3.43,3.427a.571.571,0,0,1,.017.788L12.227,14.03a.572.572,0,1,1-.81-.807Z"
              transform="translate(14.196 -11.246) rotate(90)"
              fill="#212121"
            />
          </svg>
        </i>
      </div>
      <div
        :class="
          idAddress == location.id
            ? 'active-content-address'
            : 'content-address'
        "
      >
        <div class="address-input">
          <label for="">{{ $t("government") }}</label>
          <input type="text" :placeholder="location.government.name" disabled />
        </div>
        <div class="address-input">
          <label for="">{{ $t("area") }}</label>
          <input type="text" :placeholder="location.region" disabled />
        </div>
        <div class="address-input full-address-input">
          <label for="">{{ $t("address") }}</label>
          <input type="text" :placeholder="location.address" disabled />
        </div>
        <div class="address-input" :class="hasAccess ? 'editable-input' : ''">
          <label for="">{{ $t("latitude") }}</label>
          <input
            v-validate="{
              regex: /^[+-]?\d+(\.\d+)?$/,
              required: true,
            }"
            :disabled="!hasAccess"
            type="text"
            name="latitude"
            v-model="location.latitude"
          />
          <span style="font-size: 0.7rem" class="text-danger font-bold">{{
            errors.first("latitude")
          }}</span>
        </div>
        <div class="address-input" :class="hasAccess ? 'editable-input' : ''">
          <label for="">{{ $t("longitude") }}</label>
          <input
            v-validate="{
              regex: /^[+-]?\d+(\.\d+)?$/,
              required: true,
            }"
            :disabled="!hasAccess"
            type="text"
            name="longitude"
            v-model="location.longitude"
          />
          <span style="font-size: 0.7rem" class="text-danger font-bold">{{
            errors.first("longitude")
          }}</span>
        </div>
        <div
          class="address-input editable-input"
          v-if="location.is_fixed === 1 && hasAccess"
        >
          <label for="">{{ $t("fingerprintRange") }}</label>
          <input
            v-validate="{
              regex: /^[+-]?\d+(\.\d+)?$/,
              required: location.is_fixed === 0,
            }"
            name="radius"
            type="text"
            v-model="radius"
          />
          <span style="font-size: 0.7rem" class="text-danger font-bold">{{
            errors.first("radius")
          }}</span>
        </div>
        <div class="adress-btns" v-if="hasAccess">
          <button class="address-btn" @click="updateClientLocation(location)">
            {{ $t("save") }}
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.5 1V4.4C5.5 4.96005 5.5 5.24008 5.60899 5.45399C5.70487 5.64215 5.85785 5.79513 6.04601 5.89101C6.25992 6 6.53995 6 7.1 6H13.9C14.4601 6 14.7401 6 14.954 5.89101C15.1422 5.79513 15.2951 5.64215 15.391 5.45399C15.5 5.24008 15.5 4.96005 15.5 4.4V2M15.5 19V12.6C15.5 12.0399 15.5 11.7599 15.391 11.546C15.2951 11.3578 15.1422 11.2049 14.954 11.109C14.7401 11 14.4601 11 13.9 11H7.1C6.53995 11 6.25992 11 6.04601 11.109C5.85785 11.2049 5.70487 11.3578 5.60899 11.546C5.5 11.7599 5.5 12.0399 5.5 12.6V19M19.5 7.32548V14.2C19.5 15.8802 19.5 16.7202 19.173 17.362C18.8854 17.9265 18.4265 18.3854 17.862 18.673C17.2202 19 16.3802 19 14.7 19H6.3C4.61984 19 3.77976 19 3.13803 18.673C2.57354 18.3854 2.1146 17.9265 1.82698 17.362C1.5 16.7202 1.5 15.8802 1.5 14.2V5.8C1.5 4.11984 1.5 3.27976 1.82698 2.63803C2.1146 2.07354 2.57354 1.6146 3.13803 1.32698C3.77976 1 4.61984 1 6.3 1H13.1745C13.6637 1 13.9083 1 14.1385 1.05526C14.3425 1.10425 14.5376 1.18506 14.7166 1.29472C14.9184 1.4184 15.0914 1.59135 15.4373 1.93726L18.5627 5.06274C18.9086 5.40865 19.0816 5.5816 19.2053 5.78343C19.3149 5.96237 19.3957 6.15746 19.4447 6.36154C19.5 6.59171 19.5 6.8363 19.5 7.32548Z"
                stroke="#31006F"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <button
            v-if="location.is_fixed === 1"
            class="address-btn-delete"
            @click="deleteToken()"
          >
            {{ $t("removeDevice") }}
            <feather-icon
              icon="LockIcon"
              svgClasses="w-4 h-4"
              class="cursor-pointer"
            ></feather-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SpinnerColor2 from "../../components/table-components/SpinnerColor2.vue";
import notifyState from "@/mixins/notifyState";
import aclAccess from "@/mixins/hasAccess";
export default {
  mixins: [notifyState, aclAccess],
  data() {
    return {
      idAddress: 1,
      locations: [],
      radius: null,
      loading: false,
    };
  },
  components: {
    SpinnerColor2,
  },
  computed: {
    ...mapState("userData", ["client_locations", "user", "success_api"]),
  },
  methods: {
    ...mapActions("userData", [
      "clientLocations",
      "updateLocation",
      "revokeToken",
    ]),
    check(id) {
      if (this.idAddress === id) {
        this.idAddress = null;
      } else {
        this.idAddress = id;
      }
    },
    checkApi(name) {
      if (this.success_api) {
        this.notifySuccess(name);
      } else {
        this.notifyFaild("faild");
      }
    },
    updateClientLocation(location) {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          this.loading = true;
          let obj = { id: location.id };
          if (location.is_fixed === 1) obj["radius"] = this.radius;
          obj["latitude"] = location.latitude;
          obj["longitude"] = location.longitude;
          await this.updateLocation(obj);
          this.checkApi("editToast");
          this.loading = false;
        }
      });
    },
    async deleteToken() {
      this.loading = true;
      const userId = JSON.parse(localStorage.getItem("userData")).userId;
      await this.revokeToken({ id: userId });
      this.checkApi("deleteToast");
      this.loading = false;
    },
  },
  async created() {
    if (!this.client_locations.length) {
      this.loading = true;
      await this.clientLocations();
      this.locations = JSON.parse(JSON.stringify(this.client_locations));
      this.radius = this.user.radius;
      this.loading = false;
    } else {
      this.locations = JSON.parse(JSON.stringify(this.client_locations));
      this.radius = this.user.radius;
    }
    this.idAddress = this.locations[0].id;
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_variables.scss";
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.address {
  overflow: hidden;
}
.title-address {
  border: solid 1px #9e9e9e;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border-radius: 3px;
  margin-bottom: 8px;
  cursor: pointer;
  .icon-address {
    transition: 0.2s ease-in-out;
  }
  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 27px;
    color: #212121;
  }
}
.content-address,
.active-content-address {
  background-color: #f8f8f8;
  border-radius: 3px;
  border: solid 1px #9e9e9e;
  transition: 0.3s;
  p {
    color: #000000;
    font-size: 0.875rem;
    line-height: 1.875rem;
    font-weight: bold;
  }
}
.content-address {
  padding: 0 16px;
  opacity: 0;
  height: 0;
}
.active-content-address {
  padding: 16px;
  opacity: 1;
  height: auto;
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;
}
.address-input {
  grid-column: span 6;
  label {
    color: #000;
    margin-bottom: 8px;
    font-family: Cairo;
    font-size: 14px;
    font-weight: 600;
    line-height: 27px;
    display: block;
  }
  input {
    width: 100%;
    padding: 5px 6px;
    border-radius: 3px;
    border: solid 1px #9e9e9e;
    font-size: 14px;
    font-weight: 600;
    line-height: 27px;
    color: #9e9e9e;
    background: transparent;
  }
}
.full-address-input {
  grid-column: span 12;
}
.editable-input {
  input {
    background: #fff;
    color: #000;
  }
}
.fixed-address {
  .editable-input {
    grid-column: span 4;
  }
}
.adress-btns {
  grid-column: span 12;
  display: flex;
  align-items: center;
  gap: 16px;
}
.address-btn {
  border: 1px solid $mainColor1;
  border-radius: 50px;
  padding: 5px 39px;
  display: flex;
  gap: 8px;
  align-items: center;
  color: $mainColor1;
  transition: 0.3s ease-in-out;
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  background: transparent;
  grid-column: span 6;
  width: fit-content;
  cursor: pointer;
  svg path {
    transition: 0.3s ease-in-out;
  }
  &:hover {
    background: $mainColor1;
    color: #fff;
    svg path {
      stroke: #fff;
    }
  }
}
.address-btn-delete {
  @extend .address-btn;
  border: 1px solid #ea5455;
  color: #ea5455;
  &:hover {
    background: #ea5455;
    color: #fff;
  }
}

.active-icon-address {
  transform: rotate(180deg);
}
</style>
